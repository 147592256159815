import { FC, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { MDBDataTable } from "mdbreact";
import { useOktaAuth } from "@okta/okta-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar as solidStar } from "@fortawesome/free-solid-svg-icons";
import { faStar as regularStar } from "@fortawesome/free-regular-svg-icons";
import { faLock as lock } from "@fortawesome/free-regular-svg-icons";
import { getFormattedDate, getCurrency } from "../common/DateHelper";
import "./AllDenials.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
// import "mdbreact/dist/css/mdb.css";
import MyCaseToggle from "../MyCase/MyCaseToggle";

const AllDenialsList = ({ allDenials, userInfo, routeId }) => {
  const { authState, oktaAuth } = useOktaAuth();
  const email = authState?.accessToken?.claims.sub;
  const [tableRows, setTableRows] = useState([]);
  const [sortDirection, setSortDiection] = useState("desc");
  const [sortKey, setSortKey] = useState("");
  const history = useHistory();

  useEffect(() => {
    if (allDenials) {
      setTableRows(assembleData());
    }
  }, [allDenials]);

  useEffect(() => {
    if (allDenials) {
      setTableRows(assembleData());
    }
  }, [sortDirection]);

  const onClickPatientName = (denial, requestNumber, i) => {
    if (denial.PatientName != null) {
      history.push(`/Appeals/Detail/${requestNumber}`, {
        denial: denial,
        requestNumber: requestNumber,
        i: i,
      });
    }
  };

  const setReferralDateColor = (DateofReferral) => {
    let gap;
    let today = new Date();
    let referralDate;
    let dateColor;
    if (DateofReferral !== null) {
      referralDate = new Date(DateofReferral);
      gap = Math.ceil((today - referralDate) / (1000 * 60 * 60 * 24));
      if (gap < 31) {
        dateColor = "#4DAA50";
      } else if (gap > 31 && gap < 91) {
        dateColor = "rgba(255,255,0,0.5)";
      } else {
        dateColor = "#EA1D36";
      }
      return dateColor;
    }
  };

  const assembleData = () => {
    let patientsArray = allDenials;
    if (sortKey === "patientName") {
      if (sortDirection == "asc") {
        patientsArray = allDenials?.sort(function (a, b) {
          if (a.PatientName < b.PatientName) {
            return -1;
          }
          if (a.PatientName > b.PatientName) {
            return 1;
          }
          return 0;
        });
      } else {
        patientsArray = allDenials?.sort(function (a, b) {
          if (b.PatientName < a.PatientName) {
            return -1;
          }
          if (b.PatientName > a.PatientName) {
            return 1;
          }
          return 0;
        });
      }
    }
    let denials = patientsArray?.map((denial, i) => {

      let bgColor = setReferralDateColor(denial?.DateofReferral);
      return {
        myCase: (
          <MyCaseToggle
            allDenials={allDenials}
            email={email}
            denial={denial}
            caseCalled={false}
          />
        ),
        dischargeDateFY: denial?.DischargeYear,
        referralDate: (
          <div className="referralDate" style={{ backgroundColor: bgColor }}>
            {getFormattedDate(denial?.DateofReferral)}
          </div>
        ),
        patientName:   
        (<>
          <div
          id="table-text-referrals"
          onClick={() =>
            onClickPatientName(denial, denial?.requestNumber, i)
          }
        >
          {denial?.PatientName}
        </div>
          </>)
         ,

        account: denial?.Account,
        physician: denial?.AttendingPhysician,
        financialClass: denial?.FinancialClassCategory,
        conditionalData:
          routeId == 4
            ? getFormattedDate(denial?.appeal?.dateScreened)
            : denial.appeal?.denialReasonDeficiencyCode?.description,
        deniedAmount: (<>
          {denial?.appeal?.deniedAmount !== null && denial?.appeal !== null  ? (
            `$${getCurrency(denial?.appeal?.deniedAmount)}`
          ) : (
            <></>
          )}
        </>),
        grossCharges: (
          <>
            {denial?.GrossCharges !== null ? (
              `$${getCurrency(denial?.GrossCharges)}`
            ) : (
              <></>
            )}
          </>
        ),
        currentBalance: (
          <>
            {denial?.CurrentBalance !== null ? (
              `$${getCurrency(denial?.CurrentBalance)}`
            ) : (
              <></>
            )}
          </>
        ),
      };
    });
    return denials;
  };

  const data = {
    columns: [
      {
        label: "My Case",
        field: "myCase",
        sort: "disabled",
        width: 150,
      },
      {
        label: "FY",
        field: "dischargeDateFY",
        sort: "asc",
        width: 50,
      },
      {
        label: "Referral Date",
        field: "referralDate",
        sort: "disabled",
        width: 150,
      },
      {
        label: "Patient Name",
        field: "patientName",
        sort: "asc",
        width: 150,
      },
      {
        label: "Account",
        field: "account",
        sort: "disabled",
        width: 150,
      },
      {
        label: "Physician",
        field: "physician",
        sort: "disabled",
        width: 150,
      },
      {
        label: "Financial Class",
        field: "financialClass",
        sort: "disabled",
        width: 150,
      },
      {
        label: routeId == 4 ? "Screened Date" : "Denied Reason",
        field: "conditionalData",
        sort: "disabled",
        width: 150,
      },
      {
        label: "Denied Amount",
        field: "deniedAmount",
        sort: "disabled",
        width: 150,
      },
      {
        label: "Gross Charges",
        field: "grossCharges",
        sort: "disabled",
        width: 150,
      },
      {
        label: "Current Balance",
        field: "currentBalance",
        sort: "disabled",
        width: 150,
      },
    ],
    rows: tableRows,
  };
  return (
    <MDBDataTable
      responsive
      small
      key={data.rows.length}
      data={data}
      id="responsive-table"
      data-route= {routeId}
      searching={false}
      info={false}
      paging={true}
      autoWidth={false}
      sorting={"true"}
      onSort={(e) => {
        if (e.column === "patientName") {
          setSortDiection(e.direction);
          setSortKey(e.column);
        } else {
          setSortDiection("desc");
          setSortKey("");
        }
      }}
    />
  );
};
export default AllDenialsList;
