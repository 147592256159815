import { FC } from "react";
import { Link,useHistory } from "react-router-dom";
import '../styles.css';



const BackButton= () => {
  const history = useHistory()
  return (
    <>
     
        <a
          className= "new"       
          onClick={()=>history.goBack()}
        >
         {"Go Back"}
        </a>
    </>
  );
};

export default BackButton;
