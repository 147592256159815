import { useEffect, useState, useContext } from "react";
import { useHistory, Link } from "react-router-dom";
import { useOktaAuth } from "@okta/okta-react";
import { MDBDataTable } from "mdbreact";
import { Table, Form, Alert } from "react-bootstrap";
import { getFormattedDate } from "../common/DateHelper";
import { RolesContext } from "../context/RolesContext";

import "./Users.css";
import "../AllDenials/AllDenials.css";
import "mdbreact/dist/css/mdb.css";

const UserManagement = ({ users, userInfo, routeId }) => {
  const { authState, oktaAuth } = useOktaAuth();
  const [tableRows, setTableRows] = useState([]);
  const history = useHistory();
  const roles = useContext(RolesContext);
  const userRoles = roles?.roles;

  

  useEffect(() => {
    if (users) {
      setTableRows(assembleData());
    }
  }, [users]);

  const assembleData = () => {
    let denials = users?.map((user, i) => {
      return {
        lastName: user.lastName,
        firstName: user.firstName,
        email: user.email,
        type: user.type,
        activeRecord: (
          <Form.Check type="checkbox" disabled checked={user.activeRecord} />
        ),
        createdDate:
          user?.createdDate !== null && user?.createdDate?.includes(" ")
            ? user?.createdDate?.split(" ")[0]
            : user?.createdDate,
        createdBy: user?.createdBy,
        modifiedDate:
          user?.modifiedDate !== null && user?.modifiedDate?.includes(" ")
            ? user?.modifiedDate?.split(" ")[0]
            : user?.modifiedDate,
        modifiedBy: user.modifiedBy,
        admin: <Form.Check type="checkbox" disabled checked={user.admin} />,
        edit: (
          <Link
            className="link-btn-display"
            to={{ pathname: `/user/edit/${user.id}`, state: { user: user } }}
          >
            Edit
          </Link>
        ),
      };
    });
    return denials;
  };

  const data = {
    columns: [
      {
        label: "Last Name",
        field: "lastName",
        sort: "disabled",
      },
      {
        label: "First Name",
        field: "firstName",
        sort: "disabled",
      },
      {
        label: "Email",
        field: "email",
        sort: "disabled",
      },
      {
        label: "Type",
        field: "type",
        sort: "disabled",
      },
      {
        label: "Active Record",
        field: "activeRecord",
        sort: "disabled",
      },
      {
        label: "Created Date",
        field: "createdDate",
        sort: "disabled",
      },
      {
        label: "Created By",
        field: "createdBy",
        sort: "disabled",
      },
      {
        label: "Modified Date",
        field: "modifiedDate",
        sort: "disabled",
      },
      {
        label: "Modified By",
        field: "modifyBy",
        sort: "disabled",
      },
      {
        label: "Admin",
        field: "admin",
        sort: "disabled",
      },
      {
        label: "",
        field: "edit",
        sort: "disabled",
      },
    ],
    rows: tableRows,
  };

  if(routeId == 7 && userRoles?.isAdmin) {
    return (
      <MDBDataTable
        responsive
        small
        key={data.rows.length}
        data={data}
        id="responsive-table-users"
        searching={true}
        info={false}
        paging={true}
        autoWidth={false}
      />
    );
   
  }
  else {
    return (<Alert severity="error" style={{ color: "#EA1D36", textAlign: 'center', border: 'none', background: 'white' }}>
    You don't have access to this view.
  </Alert>)
  }
  
};
export default UserManagement;
