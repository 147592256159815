import { useOktaAuth } from "@okta/okta-react";
import React, { useState, useEffect, useContext, useRef } from "react";
import { useParams, useLocation } from "react-router-dom";
import { homeApi } from "../API";
import { Form, Row, Col, Alert, Button } from "react-bootstrap";
import Spinner from "../Spinner";
import { RolesContext } from "../context/RolesContext";
import HeaderName from "../HeaderName/HeaderName";
import AlertModal from "../common/AlertModal";
import {
  getFormattedDate,
  getFormattedDateFromDate,
  getFormattedTimeFromDateTime,
  formatDate,
} from "../common/DateHelper";
import "./AppealsDetails.css";
import axios from "axios";
import MyCaseToggle from "../MyCase/MyCaseToggle";
// import { createFilterOptions, Link, Alert } from "@material-ui/core";
import AppealCaseDetailUploadAttachments from "./AppealCaseDetailUploadAttachments";

const AppealsCaseDetailInfo = ({ caseDetails }) => {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [redirect, setRedirect] = useState({ redirect: false, message: "" });
  const [modalShow, setModalShow] = React.useState(false);
  const [redirectCollector, setRedirectCollector] = useState({
    redirect: false,
    message: "",
    errorMessage: "",
  });
  const [data, setData] = useState({
    transferredToVendor: caseDetails?.appeal?.transferredToVendor,
    deniedDays: caseDetails?.appeal?.deniedDays,
    deniedAmount: caseDetails?.appeal?.deniedAmount,
    expectedAmount: caseDetails?.appeal?.expectedPayment,
    wonOnAppeal: caseDetails?.appeal?.wonOnAppeal,
    dueDate: caseDetails?.appeal?.dueDate,
  });
  const { authState, oktaAuth } = useOktaAuth();
  const [maxCount, setMaxCount] = useState(10);
  const [messageAlert, setMessageAlert] = useState("");
  const accessToken = authState?.accessToken?.accessToken;
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [error, setError] = useState(false);
  const [uploadedFilesResponse, setUploadedFilesResponse] = useState([]);
  const [fileLimit, setFileLimit] = useState(false);
  const roles = useContext(RolesContext);
  const userRoles = roles?.roles;
  const email = authState?.accessToken?.claims.sub;
  let btnRef = useRef();

  const uploadFileHandler = (e) => {
    const chosenFiles = Array.prototype.slice.call(e.target.files);
    const uploaded = [...uploadedFiles];
    let limitExceeded = false;
    chosenFiles?.some((file) => {
      if (uploaded.findIndex((f) => f.name === file.name) === -1) {
        uploaded.push(file);
        if (uploaded.length === maxCount) setFileLimit(true);
        if (uploaded.length > maxCount) {
          setMessageAlert(<>{`You can only add a maximum of `}<b>{maxCount}</b>{` files`}</>);
          setModalShow(true);
          setFileLimit(false);
          limitExceeded = true;
          document.getElementById('myFile').value = '';
          return true;
        }
      }
    });
    if (!limitExceeded) setUploadedFiles(uploaded);
  };

  const handleChange = ({ target: { name, value, checked } }) => {
    setRedirectCollector({ redirect: false, message: "", errorMessage: "" });
    if (error === false) {
      btnRef.current.removeAttribute("disabled");
    }
    if (name == "transferredToVendor") {
      setData({
        ...data,
        [name]: checked,
      });
    } else {
      setData({
        ...data,
        [name]: value,
      });
    }
  };

  const handleUploadAttachment = (e) => {
    if (uploadedFiles.length == 0) {
      setMessageAlert(`Please select a file to upload`);

      return setModalShow(true);
    }
    const cancelToken = axios.CancelToken.source();
    let data = new FormData();
    uploadedFiles.forEach((file, i) => {
      data.append(`files`, file);
    });

    setLoading(true);

    //Create new FormData object and append files
    homeApi
      .postCaseAttachment(id, data, accessToken, cancelToken)
      .then((response) => {
        setLoading(false);
        setUploadedFilesResponse(response);
        window.location.reload(false);
      })
      .catch((err) => {
        console.error(err);
        setRedirect({ redirect: false, message: "error" });
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const cancelToken = axios.CancelToken.source();
    if (btnRef.current) {
      btnRef.current.setAttribute("disabled", "true");
    }

    const postBody = {
      accountNumber: caseDetails?.Account,
      requestNumber: caseDetails?.requestNumber,
      ...data,
    };
    homeApi
      .postEditCaseSecondary(
        caseDetails?.requestNumber,
        postBody,
        accessToken,
        cancelToken
      )
      .then((res) => {
        setData(res);
        setLoading(false);
        setRedirectCollector({
          redirect: false,
          message: "success",
          errorMessage: "",
        });
        // setTimeout(() => {
        //   window.location.href = `/users`;
        // }, 1500);
      })
      .catch((e) => {
        console.error(e);
        setLoading(false);
        setRedirectCollector({
          redirect: false,
          message: "error",
          errorMessage: "Error in submitting the form, please try again!",
        });
        // setTimeout(() => {
        //   window.location.reload();
        // }, 2000);
      });
  };

  return (
    <React.Fragment>
      <AlertModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        message={messageAlert}
      ></AlertModal>
      {redirect.message === "error" ? (
        <>
          {window.scrollTo(0, 0)}
          <Alert severity="error" style={{ color: "#EA1D36" }}>
            Error in submitting the upload attachment, please try again!
          </Alert>
        </>
      ) : (
        <></>
      )}
      {redirectCollector.message === "success" ? (
        <>
          {window.scrollTo(0, 0)}
          <div className="alert alert-success" role="alert">
          <i className="fa fa-check-circle"></i>
            <span className="span-form">Form is successfully submitted!</span>
          </div>
        </>
      ) : redirectCollector.message === "error" ? (
        <>
          {window.scrollTo(0, 0)}
          <div className="alert alert-error" role="alert">
          <i className="fa fa-exclamation-circle" aria-hidden="true"></i>
          <span className="span-form">{redirectCollector.errorMessage} </span>
          </div>
        </>
      ) : (
        <></>
      )}
      <div className="container table-responsive">
        <div className="col-lg-12 col-md-5">
          <h2 className="heading-denial">{caseDetails?.PatientName}</h2>
          <dl className="dl-horizontal">
            <dt>My case</dt>
            <dd>
              {
                <MyCaseToggle
                  email={email}
                  denial={caseDetails}
                  allDenials={[]}
                  caseCalled={true}
                />
              }
            </dd>
            <dt>Account</dt>
            <dd>{caseDetails?.Account}</dd>
            <dt>Visit</dt>
            <dd>{`${getFormattedDate(
              caseDetails?.AdmitDate
            )} - ${getFormattedDate(caseDetails?.DischargeDate)}`}</dd>
            <dt>Physician</dt>
            <dd>{caseDetails?.AttendingPhysician}</dd>
            <dt>Insurance</dt>
            <dd>{caseDetails?.InsuranceCarrierCode}</dd>
            <dt>Financial Class</dt>
            <dd>{caseDetails?.FinancialClass}</dd>
            <dt>Category</dt>
            <dd>{caseDetails?.FinancialClassCategory}</dd>
            <dt>Gross Charges</dt>
            <dd>
              {caseDetails?.GrossCharges !== null ? "$ " : <></>}
              {caseDetails?.GrossCharges}
            </dd>
            <dt>Current Balance</dt>
            <dd>
              {caseDetails?.CurrentBalance !== null ? "$ " : <></>}
              {caseDetails?.CurrentBalance}
            </dd>
            <dt>Write Off Amount</dt>
            <dd>
              {caseDetails?.WriteOffAmt !== null ? "$" : <></>}
              {caseDetails?.WriteOffAmt}
            </dd>
            <dt>2nd Level Appeal</dt>
            <dd>
              <Form.Check
                type="checkbox"
                disabled
                defaultChecked={caseDetails?.appeal?.secondLevel}
              />
            </dd>
            <dt>Transferred to Vendor</dt>
            <dd>
              <Form.Check
                type="checkbox"
                name="transferredToVendor"
                onChange={handleChange}
                disabled={userRoles.role == "COLL" ? false : true}
                defaultChecked={caseDetails?.appeal?.transferredToVendor}
              />
            </dd>
            <dt>Denied Days</dt>
            <dd>
              <Form.Control
                type="number"
                name="deniedDays"
                className= {userRoles.role == "COLL" ? "coll_highlight" : ""}
                onChange={handleChange}
                disabled={userRoles.role == "COLL" ? false : true}
                defaultValue={caseDetails?.appeal?.deniedDays}
              />
            </dd>

            <dt>Denied Amount</dt>
            <dd>
              <Form.Control
                type="number"
                step="0.01"
                name="deniedAmount"
                className= {userRoles.role == "COLL" ? "coll_highlight" : ""}
                onChange={handleChange}
                disabled={userRoles.role == "COLL" ? false : true}
                defaultValue={caseDetails?.appeal?.deniedAmount}
              />
            </dd>
            <dt>Expected Amount</dt>
            <dd>
              <Form.Control
                type="number"
                step="0.01"
                name="expectedAmount"
                className= {userRoles.role == "COLL" ? "coll_highlight" : ""}
                onChange={handleChange}
                disabled={userRoles?.role == "COLL" ? false : true}
                defaultValue={caseDetails?.appeal?.expectedPayment}
              />
            </dd>
            <dt>Amount Won on Appeal</dt>
            <dd>
              <Form.Control
                type="number"
                step="0.01"
                name="wonOnAppeal"
                className= {userRoles.role == "COLL" ? "coll_highlight" : ""}
                onChange={handleChange}
                disabled={userRoles?.role == "COLL" ? false : true}
                defaultValue={caseDetails?.appeal?.wonOnAppeal}
              />
            </dd>
            <dt>Appeal Due Date</dt>
            <dd>
              <Form.Control
                style={{ cursor: "pointer" }}
                type="date"
                name="dueDate"
                className= {userRoles.role == "COLL" ? "coll_highlight" : ""}
                onChange={handleChange}
                disabled={userRoles?.role == "COLL" ? false : true}
                defaultValue={
                  Boolean(caseDetails?.appeal?.dueDate)
                    ? formatDate(caseDetails?.appeal?.dueDate)
                    : ""
                }
              />
            </dd>
            <dt>Last Modified By</dt>
            <dd>{caseDetails?.appeal?.modifiedBy}</dd>
            <dt>Last Modified On</dt>
            <dd>
              {caseDetails?.appeal?.modifiedDate
                ? `${getFormattedDateFromDate(
                    caseDetails?.appeal?.modifiedDate?.split("T")[0]
                  )} ${getFormattedTimeFromDateTime(
                    caseDetails?.appeal?.modifiedDate
                  )}`
                : ""}
            </dd>
          </dl>
          {userRoles.role == "COLL" ? (
            <Button
              aria-controls="btn_search_drop"
              className="screening_submit"
              type="submit"
              ref={btnRef}
              onClick={handleSubmit}
            >
              Save
            </Button>
          ) : (
            <></>
          )}
        </div>
      </div>
      <div className="container table-responsive attachment">
        <div className="col-lg-12 col-md-5">
          <h3 className="heading-denial-upload-attachment">
            Collector Attachments
          </h3>
          <AppealCaseDetailUploadAttachments
            disable={false}
            attachment={true}
            handleUploadAttachment={handleUploadAttachment}
            uploadFileHandler={uploadFileHandler}
            uploadedFiles={uploadedFiles}
            uploadedFilesResponse={uploadedFilesResponse}
            caseDetails={caseDetails}
            loading={loading}
          />
        </div>
      </div>
    </React.Fragment>
  );
};

export default AppealsCaseDetailInfo;
