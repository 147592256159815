import { useEffect, useState } from "react";
import { useOktaAuth } from "@okta/okta-react";
import { Navbar, Nav, NavDropdown } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import  {MenuItems} from "./MenuItems";
import "./Navbar.css";

const Tab = () => {
  const { authState } = useOktaAuth();
  const history = useHistory();
  let menuItem = MenuItems();
  let key;
  let lastTab;

  const menuItemClick = (item) => {
    history.push(item.path || "/")
    handleDropdownSelect();
   }
 

  const handleDropdownSelect = () => {
    let parentNav = document.getElementById("responsive-navbar-nav");
    let child = parentNav.getElementsByClassName("nav-link");
    
    localStorage.setItem('lastTab',window.location.pathname);
    lastTab = localStorage.getItem('lastTab');
    for (let i = 0; i < child.length; i++) {
    let eventValue = child[i].getAttribute("data-rr-ui-event-key");
      if (lastTab == eventValue ) {
        child[i].className += " active";
      }
      child[i].addEventListener("click", function () {
        let current = document.getElementsByClassName("active");
        current[0].className = current[0].className.replace(" active", "");
        // If there's no active class
        if (current.length > 0) {
          current[0].className = current[0].className.replace(" active", "");
        }
        // Add the active class to the current/clicked button
        child[i].className += " active";
      });
    }
  };



  useEffect(() => {
    handleDropdownSelect();
  }, [authState?.accessToken?.accessToken]);

  if (window.location.pathname == '/') {
    key = 'All Denials';
  }
  else if (window.location.pathname == 'alldenials') {
    key = 'All Denials';
  }
  else if (window.location.pathname.includes('unscreened')) {
    key = "Unscreened";
  }
  else if (window.location.pathname.includes('screened')) {
    key = "Screened";
  }
  else if (window.location.pathname.includes('pendinginfo')) {
    key = "Pending Info";
  }
  else if (window.location.pathname.includes('mycases')) {
    key = "My Cases";
  }
  else if (window.location.pathname.includes('users')) {
    key = "Users";
  }
  else {
    key = 'All Denials';
  }


  return (
    <>
      <Navbar collapseOnSelect  className="tab-lg">
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse className="" id="responsive-navbar-nav">
          <Nav variant="pills" className={`container container-fluid `} activeKey={key}>
            <>
              {menuItem?.subNav?.map((item, index) => {
                return (
                  <Nav.Link
                  eventKey={item?.path}
                  onClick={() => menuItemClick(item)}
                    // href={item.path}
                    key={index}
                    className="nav-link"
                  >
                    {item?.title}
                  </Nav.Link>
                );
              })}
            </>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </>
  );
};

export default Tab;
