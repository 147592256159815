import React, { createContext, useState, useEffect } from "react";
import { useOktaAuth } from "@okta/okta-react";
import { homeApi } from "../API";

export const RolesContext = createContext();

const RolesContextProvider = (props) => {
   const [roles, setRoles] = useState();
   const { authState, oktaAuth } = useOktaAuth();
   const accessToken = authState?.accessToken?.accessToken;

  useEffect(() => {
    homeApi.getRoles(accessToken).then(res => {
        setRoles(res);
        console.log(res)
      })
      .catch((e) => {
        console.error(e);
      });
  }, [authState.isAuthenticated]);

  return (
    <RolesContext.Provider
      value={{ roles, setRoles }}
    >
      {props.children}
    </RolesContext.Provider>
  );
};

export default RolesContextProvider;
