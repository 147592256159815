import "./HeaderName.css";
import BackButton from "../common/BackButton";



const HeaderName = (name,routeId) => {
  return (
    <>
      <div className="hn-header home">
        <div className="btn-go-back" style={{paddingRight: '10rem'}}>
        {name.name}
        {name.routeId == 2 ? <BackButton/> : <></> }
        </div>
      </div>
    </>
  );
};

export default HeaderName;
