import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

const AlertModal = (props) => {
  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <p className="message-modal">{props.message}</p>
      </Modal.Body>
      <Modal.Footer>
        <Button className="screening_submit" onClick={props.onHide}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AlertModal;
