import { FC, useEffect, useState } from "react";
import { FormControl, InputGroup, Button } from "react-bootstrap";
import '../styles.css';


const SearchBar = ({ placeholder, value = "", defaultValue ,  onSearch, onTextChange, source }) => {
  const [text, setText] = useState(value);
  useEffect(() => {
    setText(defaultValue)
  }, [defaultValue]);
  return (
    <InputGroup>
      <FormControl
        style={{height: '2.5rem', borderRightColor: 'transparent', borderRadius: '0'}}
        placeholder={placeholder}
        value={text}
        onChange={(e) => {
          setText(e.target.value);
          onTextChange(e.target.value);
        }}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            onSearch(text);
          }
        }}
      />
      {text.length > 0 ? (
        <Button
          className="button-search"
          onClick={() => {
            setText("");
          }}
        >
          <i className="fa fa-times"></i>
        </Button>
      ) : null}
      <Button
        className="button-search"
        onClick={() => {
          onSearch(text);
        }}
      >
        <i className="fa fa-search"></i>
      </Button>
    </InputGroup>
  );
};

export default SearchBar;
