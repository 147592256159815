import React, { useState, useEffect } from "react";
import { useOktaAuth } from "@okta/okta-react";
import { Link, Redirect } from "react-router-dom";

const Home = () => {
  const { authState, oktaAuth } = useOktaAuth();
  const [userInfo, setUserInfo] = useState(null);


  // fetch user information once login through OKTA
  useEffect(() => {
    if (!authState?.isAuthenticated) {
      // When user isn't authenticated, forget any user info
      setUserInfo(null);
    } else {
      oktaAuth.getUser().then((info) => {
        setUserInfo(info);
      });
    }
  }, [authState, oktaAuth]); // Update if authState changes


  if (authState?.isPending) {
    return <div>Loading...</div>;
  } else if (!authState?.isAuthenticated) {
    oktaAuth.signInWithRedirect();
  }

  return <Redirect to={{ pathname: "/alldenials" }} />;
};

export default Home;
