import axios from "axios";
import { API_URL, IP_URL } from "../../config";

export async function getAllDenials(query, routeId, token, cancelToken) {
  let baseUrl;
  let url;
  switch (routeId) {
    case 6:
      baseUrl = "Denials/mycases";
      break;
      case 3:
        baseUrl = "Denials/unscreened";
        break;
        case 4:
        baseUrl = "Denials/screened";
        break;
        case 5:
          baseUrl = "Denials/pendinginfo";
          break;
          case 7:
          baseUrl = "User";
          break;
    case 1:
      baseUrl = "Denials";
      break;
    default:
      baseUrl = "Denials";
  }

  if(routeId == 7) {
    url = `${API_URL}/${baseUrl}`
  }
  else {
    url = `${API_URL}/${baseUrl}?Pagenumber=${query.Pagenumber}&PageSize=${query.PageSize}&LookBack=${query.LookBack}&SearchTerm=${query.SearchTerm}`
  }

  const responseData = await axios({
    url: url ,
    method: "get",
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    cancelToken: cancelToken?.token,
  }).then((result) => result.data);

  return responseData;
}

export async function getCaseDetail(id, token, cancelToken) {
  const responseData = await axios({
    url: `${API_URL}/Case/${id}`,
    method: "get",
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    cancelToken: cancelToken?.token,
  }).then((result) => result.data);

  return responseData;
}

export async function getCaseFormConfig(id, token, cancelToken) {
  const responseData = await axios({
    url: `${API_URL}/Case/formconfig/${id}`,
    method: "get",
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    cancelToken: cancelToken?.token,
  }).then((result) => result.data);

  return responseData;
}

export async function myCase(authToken, requestNumber, add) {
  const responseData = await axios({
    url: `${API_URL}/Denials/mycase?requestNumber=${requestNumber}&add=${add}`,
    method: "put",
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${authToken}`,
    },
  }).then((result) => {
    return result;
  });
  return responseData;
}

export async function getCaseAppealLevels(token, cancelToken) {
  const responseData = await axios({
    url: `${API_URL}/Case/appeallevels`,
    method: "get",
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    cancelToken: cancelToken?.token,
  }).then((result) => result.data);

  return responseData;
}

export async function getCaseAdmitSources(token, cancelToken) {
  const responseData = await axios({
    url: `${API_URL}/Case/admitsources`,
    method: "get",
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    cancelToken: cancelToken?.token,
  }).then((result) => result.data);

  return responseData;
}

export async function getCaseScreeningDecision(token, cancelToken) {
  const responseData = await axios({
    url: `${API_URL}/Case/screeningdecision`,
    method: "get",
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    cancelToken: cancelToken?.token,
  }).then((result) => result.data);

  return responseData;
}

export async function getCaseDenialReason(token, cancelToken) {
  const responseData = await axios({
    url: `${API_URL}/Case/denialreason`,
    method: "get",
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    cancelToken: cancelToken?.token,
  }).then((result) => result.data);

  return responseData;
}

export async function getCaseCollector(token, cancelToken) {
  const responseData = await axios({
    url: `${API_URL}/Case/collectors`,
    method: "get",
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    cancelToken: cancelToken?.token,
  }).then((result) => result.data);

  return responseData;
}

export async function getAttachment(id, fileId, token, cancelToken) {
  const responseData = await axios({
    url: `${API_URL}/Case/collector/${id}/attachment/${fileId}`,
    method: "get",
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    cancelToken: cancelToken?.token,
  }).then((result) => result.data);

  return responseData;
}

export async function getAppealAttachment(id, fileId, token, cancelToken) {
  const responseData = await axios({
    url: `${API_URL}/Case/${id}/attachment/${fileId}`,
    method: "get",
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    cancelToken: cancelToken?.token,
  }).then((result) => result.data);

  return responseData;
}



export async function postCaseAttachment(id, formData, token, cancelToken) {
  const responseData = await axios({
    url: `${API_URL}/Case/collector/${id}/attachment`,
    method: "post",
    headers: {
      "Content-type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    },
    data: formData,
    cancelToken: cancelToken?.token,
  }).then((result) =>  result.data);
  return responseData;
}

export async function postCase(id, formData, token, cancelToken) {
  const responseData = await axios({
    url: `${API_URL}/Case/${id}`,
    method: "post",
    headers: {
      "Content-type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    },
    data: formData,
    cancelToken: cancelToken?.token,
  }).then((result) =>  result.data);
  return responseData;
}

export async function postCreateNewUser(data, token, cancelToken) {
  const responseData = await axios({
    url: `${API_URL}/User`,
    method: "post",
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    data: data,
    cancelToken: cancelToken?.token,
  }).then((result) =>  result.data);
  return responseData;
}

// export async function getData() {
//   const responseData = await axios({
//     url: `https://ipapi.co/json/`,
//     method: "get",
   
//   }).then((result) => result.data);

//   console.log(responseData)

//   return responseData;
// }

export async function getRoles(token) {
  const responseData = await axios({
    url: `${API_URL}/User/roles`,
    method: "get",
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${token}`,
    }
  }).then((result) => result.data);

  return responseData;
}

export async function postEditCaseSecondary(id, data, token, cancelToken) {
  const responseData = await axios({
    url: `${API_URL}/Case/${id}/secondary`,
    method: "post",
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    data: data,
    cancelToken: cancelToken?.token,
  }).then((result) =>  result.data);
  return responseData;
}






