import { Form } from "react-bootstrap";
const options = require("../data/lookback.json");



const LookbackFilter = ({ onChange, initialValue, value }) => {

  return (
    <Form.Select
      onChange={(e) => {
        onChange(e);
      }}
      defaultValue={initialValue?.value}
      value={value}
      style={{borderRadius: '0', cursor:'pointer'}}
    >
      {options.map((option, index) => (
        <option key={index} value={option.value}>
          {option.display}
        </option>
      ))}
    </Form.Select>
  );
};

export default LookbackFilter;
