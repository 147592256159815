import { useOktaAuth } from "@okta/okta-react";
import React, { useState, useEffect } from "react";
import { useParams, useLocation } from "react-router-dom";
import { homeApi } from "../API";
import { Form, Row, Col, Button, Spinner } from "react-bootstrap";
import axios from "axios";
import "./AppealsDetails.css";

const AppealCaseDetailUploadAttachments = ({
  disable,
  attachment,
  uploadedFiles,
  uploadFileHandler,
  handleUploadAttachment,
  uploadedFilesResponse,
  caseDetails,
  loading,
}) => {
  const { id } = useParams();
  const { authState, oktaAuth } = useOktaAuth();
  const accessToken = authState?.accessToken?.accessToken;
  const [fileDownload, setFileDownload] = useState();
  const handleFileAttachment = (fileId, fileName) => {
    const cancelToken = axios.CancelToken.source();
    homeApi
      .getAttachment(id, fileId, accessToken, cancelToken)
      .then((response) => {
        let url = response;
        let a = document.createElement("a");
        a.href = url;
        a.download = fileName;
        a.click();
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const handleAppealFileAttachment = (fileId, fileName, appealID) => {
    const cancelToken = axios.CancelToken.source();
    homeApi
      .getAppealAttachment(appealID, fileId, accessToken, cancelToken)
      .then((response) => {
        let url = response;
        let a = document.createElement("a");
        a.href = url;
        a.download = fileName;
        a.click();
      })
      .catch((err) => {
        console.error(err);
      });
  };

  return (
    <React.Fragment>
      <Col
        xs
        lg="6"
        className={disable ? "uploadAttachment" : "uploadAttachmentfile"}
      >
        <input
          type="file"
          multiple
          accept="application/pdf, image/png, image/jpeg"
          onChange={uploadFileHandler}
          className="myfile"
          id="myFile"
          name="myfile"
          disabled={disable}
        />
        {attachment === false ? (
          <>
            <ul>
              {caseDetails?.appeal?.attachments?.map((file, i) => (
                <li
                  className="downloadAttachment"
                  key={i}
                  onClick={() =>
                    handleAppealFileAttachment(
                      file?.id,
                      file?.fileName,
                      caseDetails?.appeal?.id
                    )
                  }
                >
                  {file?.fileName}
                </li>
              ))}
            </ul>
          </>
        ) : (
          <></>
        )}

        {loading ? (
          <div className="beat-loader" style={{ textAlign: "center" }}>
            <Spinner size="sm" animation="border"></Spinner>
          </div>
        ) : (
          <></>
        )}
        {attachment === true ? (
          <>
            <Button
              aria-controls="btn_search_drop"
              className="screening_submit"
              id="save-new-file"
              onClick={handleUploadAttachment}
            >
              Save New File to Case
            </Button>
          </>
        ) : (
          <></>
        )}
      </Col>
      {attachment === true ? (
        <>
          <h3 className="heading-denial-upload-attachment">
            Existing Attachments
          </h3>
          <ul>
            {caseDetails?.collectorAttachments?.map((file, i) => (
              <li
                className="downloadAttachment"
                key={i}
                onClick={() => handleFileAttachment(file?.id, file?.fileName)}
              >
                {file?.fileName}
              </li>
            ))}
          </ul>
        </>
      ) : (
        <></>
      )}
    </React.Fragment>
  );
};

export default AppealCaseDetailUploadAttachments;
