export const getFormattedDate = (date) => {
    var dateFormat = new Date(date)
    return dateFormat.toLocaleDateString("en-US", { year: "numeric", month: "2-digit", day: "2-digit" })
  };

  export const getFormattedDateFromDate = (date) => {
    if (date === undefined || date == null) {
      return "";
    }
  
    if (typeof date === "string") {
      date = new Date(date);
      date = new Date(date.getTime() + date.getTimezoneOffset() * 60000)
    }

    var dd = String(date.getDate()).padStart(2, "0");
    var mm = String(date.getMonth() + 1).padStart(2, "0"); //January is 0!
    var yyyy = date.getFullYear();
  
    return mm + "/" + dd + "/" + yyyy;
  };

  export const getFormattedTimeFromDateTime = (date) => {
    if (date === undefined || date == null) {
      return "";
    }
  
    if (typeof date === "string") {
      date = new Date(date);
    }
    var hours = String(date.getHours());
    var minutes = String(date.getMinutes()).padStart(2, "0"); 
    var ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours!=0 ? hours : 12; 
   var strTime  = String(hours).padStart(2, "0") + ":" + minutes  +" " + ampm;
   return  " " + strTime;
    
  };

  export const getCurrency = (amount) => {
   return  amount?.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
  
  };

  export const formatDate = (dateValue) => {
    const [month, date, year] = dateValue.split(" ")[0].split("/");
    return `${year}-${month}-${date}`;
  };

