import { FC, useCallback, useContext, useEffect, useState } from "react";
import  {RolesContext}  from "../context/RolesContext";

export const MenuItems = () => {
  const roles = useContext(RolesContext);
  const userRoles = roles?.roles;
  let menuItems;
  
  if(userRoles?.isAdmin) {
    menuItems = {
      subNav: [
        {
          title: "All denials",
          path: "/alldenials",
        },
        {
          title: "Unscreened",
          path: "/unscreened",
        },
        {
          title: "Screened",
          path: "/screened",
        },
        {
          title: "Pending Info",
          path: "/pending",
        },
        {
          title: "My Cases",
          path: "/mycases",
        },
        {
          title: "Users",
          path: "/users",
        }
      ],
    }
  }
  else {
    menuItems = {
      subNav: [
        {
          title: "All denials",
          path: "/alldenials",
        },
        {
          title: "Unscreened",
          path: "/unscreened",
        },
        {
          title: "Screened",
          path: "/screened",
        },
        {
          title: "Pending Info",
          path: "/pending",
        },
        {
          title: "My Cases",
          path: "/mycases",
        }
      ],
    }
  }

  return menuItems
 
};
