import { useOktaAuth } from "@okta/okta-react";
import React, { useState, useEffect } from "react";
import { Navbar, Nav, NavDropdown } from "react-bootstrap";
import Logo from "../../assets/CHLA-LOGO-horiz.png";
import Tab from "./Tab.js";
import { homeApi } from "../API";


import "./Navbar.css";
let username;

const NavbarComponent = () => {
  const { authState, oktaAuth } = useOktaAuth();
  const [userInfo, setUserInfo] = useState(null);
  const accessToken = authState?.accessToken?.accessToken;

  const login = async () => {
    oktaAuth.signInWithRedirect();
  };

  const logout = async () => {
    oktaAuth.signOut();
  };

  useEffect(() => {
    if (!authState.isAuthenticated) {
      // When user isn't authenticated, forget any user info
      setUserInfo(null);
    } else {
      oktaAuth
        .getUser()
        .then((info) => {
          if (info === undefined || info == null) {
            logout();
          } else {
            username = `Hello, ${info.given_name} ${info.family_name}`;
            setUserInfo(info);
            
          }
        })
        .catch();
    }
  }, [authState.isAuthenticated]);

  if (authState.isPending) return null;

  const button = authState.isAuthenticated ? (
    <a className="btn nav-btn-auth" id="logout" onClick={logout}>
      Logout
    </a>
  ) : (
    <a className="btn nav-btn-auth" id="login" onClick={login}>
      Login
    </a>
  );

  return (
    <>
      <Navbar collapseOnSelect expand="lg" className="navbar-lg">
        <div className="container-fluid">
          <img src={Logo} className="img_logo" alt="Image" />

          <Navbar.Brand className="navbar-band">
            <span className="navbar-pipe linkSeparator"></span>
            <a className="nav-link active" aria-current="page" href="/">
              <span className="navbar-heading">Denials Worklist</span>
            </a>
          </Navbar.Brand>
          {authState?.isAuthenticated && (
            <span className="navbar-text">
              <a className="navbar-light">{username}</a>
            </span>
          )}
        </div>
      </Navbar>
      <Tab />
    </>
  );
};

export default NavbarComponent;
