import { useOktaAuth } from "@okta/okta-react";
import { useState, useEffect, useContext } from "react";
import { homeApi } from "../API";
import Spinner from "../Spinner";
import HeaderName from "../HeaderName/HeaderName";
import "../styles.css";
import AllDenialsList from "./AllDenialsList";
import axios from "axios";
import LookbackFilter from "../common/LookbackFilter";
import SearchBar from "../common/SearchBar";
import UserManagement from "../UserManagement/UserManagement";
import { Redirect, Link } from "react-router-dom";
import { RolesContext } from "../context/RolesContext";

const lookbackOptions = require("../data/lookback.json");

const AllDenials = ({ name, routeId }) => {
  const [error, setError] = useState();
  const [loading, setLoading] = useState(true);
  const { authState, oktaAuth } = useOktaAuth();
  const accessToken = authState?.accessToken?.accessToken;
  const cancelToken = axios.CancelToken.source();
  const [redirect, setRedirect] = useState({
    message: "",
    errorMessage: "",
  });

  const pageSize = 12;

  const [query, setQuery] = useState({
    PageSize: 12,
    Pagenumber: 1,
    LookBack: lookbackOptions[0].value,
    SearchTerm: "",
  });

  const [allDenials, setAllDenials] = useState([]);
  const { roles, setRoles } = useContext(RolesContext);

  const [userInfo, setUserInfo] = useState(null);

  useEffect(() => {
    if (!authState.isAuthenticated) {
      // When user isn't authenticated, forget any user info
      setUserInfo(null);
    } else {
      oktaAuth
        .getUser()
        .then((info) => {
          setUserInfo(info);
        })
        .catch((e) => {
          console.error(e);
        });
    }
  }, [authState.isAuthenticated]);

  useEffect(() => {
    fetchData();
  }, [query]);

  useEffect(() => {
    setQuery({
      PageSize: 12,
      Pagenumber: 1,
      LookBack: lookbackOptions[0].value,
      SearchTerm: "",
    });
  }, [routeId]);

  // API integration

  const fetchData = () => {
    const cancelToken = axios.CancelToken.source();
    setLoading(true);
    homeApi
      .getAllDenials(query, routeId, accessToken, cancelToken)
      .then((data) => {
        setAllDenials(data);
        setRedirect({
          message: "success",
          errorMessage: "",
        });
        setLoading(false);
      })
      .catch((e) => {
        if (!axios.isCancel(e)) {
          console.error(e);
          setError(e);
          setRedirect({
            message: "error",
            errorMessage: "Error, please try again!",
          });
          setLoading(false);
        }
      });

    homeApi
      .getRoles(accessToken)
      .then((res) => {
        setRoles(res);
      })
      .catch((e) => {
        console.error(e);
      });
    return () => {
      cancelToken.cancel();
    };
  };

  let result;
  if (loading) {
    result = (
      <div className="col loader mb-3">
        <Spinner />
      </div>
    );
  } else if (!loading && allDenials?.length == 0) {
    result = <div className="error">No Denials Found.</div>;
  } else if (redirect.message === "error") {
    result = (
      <>
        {window.scrollTo(0, 0)}
        <div className="error">{redirect.errorMessage}</div>
      </>
    );
  } else if (routeId == 7) {
    result = (
      <UserManagement
        userInfo={userInfo}
        users={allDenials}
        routeId={routeId}
      />
    );
  } else {
    result = (
      <AllDenialsList
        routeId={routeId}
        allDenials={allDenials}
        userInfo={userInfo}
      />
    );
  }

  return (
    <div>
      <HeaderName name={name} routeId={routeId} />
      <div className="row row-container">
        <div
          className="d-flex row justify-content-between "
          style={{ paddingLeft: "10rem" }}
        >
          {routeId == 7 ? (
            <>
              <Link
                className="create-new-users"
                to={{
                  pathname: `/user/create`,
                  aboutProps: {},
                }}
              >
                Create new
              </Link>
            </>
          ) : (
            <>
              <div className="col-sm-5 col-lg-3 col-xl-3 mb-3 lookback-filter">
                <LookbackFilter
                  onChange={(e) => {
                    setQuery((q) => ({
                      ...q,
                      LookBack: e.target.value,
                      Pagenumber: 1,
                    }));
                    window.scrollTo(0, 0);
                  }}
                  value={query.LookBack}
                />
              </div>
              <div className="col-sm-5 col-lg-4 col-xl-6 search-bar">
                <SearchBar
                  placeholder="Search by account, patient name, financial year"
                  value={query.SearchTerm}
                  defaultValue={query.SearchTerm}
                  onSearch={(text) => {
                    setQuery((q) => ({
                      ...q,
                      SearchTerm: text,
                      Pagenumber: 1,
                    }));
                    window.scrollTo(0, 0);
                  }}
                  onTextChange={(text) => {
                    setQuery((q) => ({
                      ...q,
                      SearchTerm: text,
                      Pagenumber: 1,
                    }));
                    window.scrollTo(0, 0);
                  }}
                />
              </div>
            </>
          )}
        </div>
      </div>
      {result}
    </div>
  );
};

export default AllDenials;
