
import { useOktaAuth } from "@okta/okta-react";
import { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar as solidStar } from "@fortawesome/free-solid-svg-icons";
import { faStar as regularStar } from "@fortawesome/free-regular-svg-icons";
import { faLock as lock } from "@fortawesome/free-regular-svg-icons";
import axios from "axios";
import { homeApi } from "../API";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "mdbreact/dist/css/mdb.css";





const MyCaseToggle = ({ allDenials, email, denial, caseCalled }) => {
  const { authState, oktaAuth } = useOktaAuth();
  const accessToken = authState?.accessToken?.accessToken;
        let icon;
        const [myCase, setMyCase] = useState(
          caseCalled && denial?.case !== null && denial?.case?.activeRecord == true ? [denial.requestNumber] : allDenials
              .filter((denial) => denial?.case?.email == email)
              .map((denial) => denial.requestNumber)
          );
          const [myCaseDetail, setMyCaseDetail] = useState(
            denial
          );
          const myCaseRequest = (requestNumber, add) => {
            const cancelToken = axios.CancelToken.source();
        
            homeApi
              .myCase(accessToken, requestNumber, add)
              .then((res) => {})
              .catch((e) => {
                
                if (!axios.isCancel(e)) {
                  console.error(e);
                }
              });
            return () => {
              cancelToken.cancel();
            };
          };

        const toggleMyCase = (denial, i) => {
            if (myCase.includes(denial.requestNumber)) {
              setMyCase([...myCase.filter((cases) => cases !== denial.requestNumber)]);
              myCaseRequest(denial.requestNumber, false);
            } else {
              const temp = [...myCase];
              temp.push(denial.requestNumber);
              setMyCase(temp);
              myCaseRequest(denial.requestNumber, true);
            }
            
          };

          if(caseCalled) {
            if (denial?.case == null) {
              if (myCase?.includes(denial?.requestNumber)) {
                icon = (
                  <div className="star-icon" onClick={() => toggleMyCase(denial)}>
                    <FontAwesomeIcon
                      icon={solidStar}
                      title={`Remove from 'My Cases'`}
                    />
                  </div>
                );
              } else {
                icon = (
                  <div className="star-icon" onClick={() => toggleMyCase(denial)}>
                    <FontAwesomeIcon icon={regularStar} title={`Add to 'My Cases'`} />
                  </div>
                );
              }
            } else {
              if (denial?.case !== null) {
                if (
                  denial?.case?.email == email &&
                  denial?.case?.activeRecord == true && myCase?.includes(denial?.requestNumber)
                ) {
                  // render full star
                  icon = (
                    <div className="star-icon" onClick={() => toggleMyCase(denial)}>
                      <FontAwesomeIcon
                        icon={solidStar}
                        title={`Remove from 'My Cases'`}
                      />
                    </div>
                  );
                } else if (
                  (denial?.case?.email == email &&
                  denial?.case?.activeRecord == false) || !myCase.includes(denial?.requestNumber)
                ) {
                  icon = (
                    <div className="star-icon" onClick={() => toggleMyCase(denial)}>
                      <FontAwesomeIcon icon={regularStar} title={`Add to 'My Cases'`} />
                    </div>
                  );
                } else {
                  // render lock
                  icon = (
                    <i
                      title={`Case is in ${denial?.case?.createdBy}'s queue.`}
                      className="fas fa-lock star-icon"
                    ></i>
                  );
                }
              }
            }



          }
          else {
            if (denial?.case == null) {
              if (myCase?.includes(denial?.requestNumber)) {
                icon = (
                  <div className="star-icon" onClick={() => toggleMyCase(denial)}>
                    <FontAwesomeIcon
                      icon={solidStar}
                      title={`Remove from 'My Cases'`}
                    />
                  </div>
                );
              } else {
                icon = (
                  <div className="star-icon" onClick={() => toggleMyCase(denial)}>
                    <FontAwesomeIcon icon={regularStar} title={`Add to 'My Cases'`} />
                  </div>
                );
              }
            } else {
              if (denial?.case !== null) {
                if (
                  denial?.case?.email == email &&
                  myCase.includes(denial?.requestNumber)
                ) {
                  // render full star
                  icon = (
                    <div className="star-icon" onClick={() => toggleMyCase(denial)}>
                      <FontAwesomeIcon
                        icon={solidStar}
                        title={`Remove from 'My Cases'`}
                      />
                    </div>
                  );
                } else if (
                  denial?.case?.email == email &&
                  !myCase.includes(denial?.requestNumber)
                ) {
                  icon = (
                    <div className="star-icon" onClick={() => toggleMyCase(denial)}>
                      <FontAwesomeIcon icon={regularStar} title={`Add to 'My Cases'`} />
                    </div>
                  );
                } else {
                  // render lock
                  icon = (
                    <i
                      title={`Case is in ${denial?.case?.createdBy}'s queue.`}
                      className="fas fa-lock star-icon"
                    ></i>
                  );
                }
              }
            }
          }
    

    
        return icon;
  };
export default MyCaseToggle;


